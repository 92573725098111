var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scoped_cover_div"},[_c('div',{staticClass:"jobs_table sp"},[_c('div',{},[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('section',[_c('div',{staticClass:"form_section1"},[_c('table',[_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"name","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Department/Location Name\n                    "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.department.department_name),expression:"department.department_name"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Department Location Name  Character Limit 30","maxlength":_vm.max},domProps:{"value":(_vm.department.department_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.department, "department_name", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"code","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Department/Location Code\n                    "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.department.department_code),expression:"department.department_code"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Department Location Code  Character Limit 30","maxlength":_vm.max},domProps:{"value":(_vm.department.department_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.department, "department_code", $event.target.value)}}})])]}}],null,true)})],1)]),_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"branch","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Branch\n                    "),_c('span',[_vm._v("*")])]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.department.branch_id),expression:"department.branch_id"}],class:errors[0] ? 'warning-border':'',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.department, "branch_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Choose Branch")]),_vm._l((_vm.branches),function(branch,i){return _c('option',{key:'branch_'+i,domProps:{"value":branch.id}},[_vm._v("\n                      "+_vm._s(branch.name)+"\n                    ")])})],2)])]}}],null,true)})],1),_c('td')])]),_vm._l((_vm.department.location),function(location,i){return _c('div',{key:'location_container'+i,staticClass:"sub_form_section"},[_c('div',{staticClass:"add_remove_btns"},[_c('a',{staticClass:"add_bt",on:{"click":_vm.addLocationFields}}),(_vm.department.location.length > 1)?_c('a',{staticClass:"remove_bt",on:{"click":function($event){return _vm.removeLocationField(i)}}}):_vm._e()]),_c('table',{staticClass:"sub_table"},[_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"Inbound Location","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                      Inbound Location\n                      "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(location.inbound_location),expression:"location.inbound_location"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Inbound Location  Character Limit 30","maxlength":_vm.max},domProps:{"value":(location.inbound_location)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(location, "inbound_location", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"Inbound Location Reference","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                      Inbound Location Reference\n                      "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(location.inbound_location_ref),expression:"location.inbound_location_ref"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Inbound Location Reference  Character Limit 30","maxlength":_vm.max},domProps:{"value":(location.inbound_location_ref)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(location, "inbound_location_ref", $event.target.value)}}})])]}}],null,true)})],1)]),_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"Outbound Location","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                      Outbound Location\n                      "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(location.outbound_location),expression:"location.outbound_location"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Outbound Location  Character Limit 30","maxlength":_vm.max},domProps:{"value":(location.outbound_location)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(location, "outbound_location", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"Outbound Location Reference","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                      Outbound Location Reference\n                      "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(location.outbound_location_ref),expression:"location.outbound_location_ref"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Outbound Location Reference  Character Limit 30","maxlength":_vm.max},domProps:{"value":(location.outbound_location_ref)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(location, "outbound_location_ref", $event.target.value)}}})])]}}],null,true)})],1)]),_c('tr',[_c('ValidationProvider',{attrs:{"name":"Is Default","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                      Is Default\n                      "),_c('span')]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(location.is_default),expression:"location.is_default"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"checkbox","true-value":"1","false-value":"0"},domProps:{"checked":Array.isArray(location.is_default)?_vm._i(location.is_default,null)>-1:_vm._q(location.is_default,"1")},on:{"click":function($event){return _vm.setDefault(i)},"change":function($event){var $$a=location.is_default,$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(location, "is_default", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(location, "is_default", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(location, "is_default", $$c)}}}})])]}}],null,true)})],1)])])})],2)])]}}])}),_c('section',{staticClass:"centered"},[_c('a',{staticClass:"link_bt bt_save",on:{"click":_vm.submit}},[_vm._v("Save")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }