<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp">
      <div class>
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <section>
          <div class="form_section1">
            <table>
              <tr>
                <td>
                  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Department/Location Name
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Department Location Name  Character Limit 30" v-model="department.department_name"  :class="errors[0] ? 'warning-border':''" :maxlength="max" />
                  </div>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider name="code" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Department/Location Code
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Department Location Code  Character Limit 30" v-model="department.department_code"  :class="errors[0] ? 'warning-border':''" :maxlength="max"/>
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider name="branch" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Branch
                      <span>*</span>
                    </label>
                    <select v-model="department.branch_id" :class="errors[0] ? 'warning-border':''">
                      <option value="">Choose Branch</option>
                      <option :value="branch.id" v-for="(branch,i) in branches" :key="'branch_'+i">
                        {{branch.name}}
                      </option>
                    </select>
                  </div>
                  </ValidationProvider>
                </td>
                <td>
                </td>
              </tr>
            </table>
            <div class="sub_form_section" v-for="(location,i) in department.location" :key="'location_container'+i">
              <div class="add_remove_btns">
                <a class="add_bt" @click="addLocationFields"></a>
                <a class="remove_bt" v-if="department.location.length > 1" @click="removeLocationField(i)"></a>
              </div>
              <table class="sub_table">
                <tr>
                  <td>
                  <ValidationProvider name="Inbound Location" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Inbound Location
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Inbound Location  Character Limit 30"  :class="errors[0] ? 'warning-border':''" v-model="location.inbound_location" :maxlength="max"/>
                    </div>
                  </ValidationProvider>
                  </td>
                  <td>
                  <ValidationProvider name="Inbound Location Reference" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Inbound Location Reference
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Inbound Location Reference  Character Limit 30"  :class="errors[0] ? 'warning-border':''" v-model="location.inbound_location_ref" :maxlength="max"/>
                    </div>
                  </ValidationProvider>
                  </td>
                </tr>
                <tr>
                  <td>
                  <ValidationProvider name="Outbound Location" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Outbound Location
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Outbound Location  Character Limit 30"  :class="errors[0] ? 'warning-border':''" v-model="location.outbound_location" :maxlength="max"/>
                    </div>
                  </ValidationProvider>
                  </td>
                  <td>
                  <ValidationProvider name="Outbound Location Reference" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Outbound Location Reference
                        <span>*</span>
                      </label>
                      <input type="text" placeholder="Outbound Location Reference  Character Limit 30"  :class="errors[0] ? 'warning-border':''" v-model="location.outbound_location_ref" :maxlength="max"/>
                    </div>
                  </ValidationProvider>
                  </td>
                </tr>
                <tr>
                  <ValidationProvider name="Is Default" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                      <label>
                        Is Default
                        <span></span>
                      </label>
                      <input type="checkbox" v-model="location.is_default" :class="errors[0] ? 'warning-border':''" true-value="1" false-value="0" @click="setDefault(i)"/>
                    </div>
                  </ValidationProvider>
                </tr>
              </table>
            </div>
            
          </div>
        </section>
      </ValidationObserver>

        <section class="centered">
          <a class="link_bt bt_save" @click="submit">Save</a>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DepartmentLocations",
  created(){
    this.fetchBranches();
  },
  data(){
    return {
      max:30,
      department:{
        department_name : "",
        department_code:"",
        branch_id:"",
        location:[
          {
            inbound_location:"",
            inbound_location_ref:"",
            outbound_location:"",
            outbound_location_ref:"",
            is_default:1
          }
        ]
      },
      branches:[]
    }
  },
  methods: {
    fetchBranches(){
      this.axios.get("api/branch/")
      .then(response => {
        this.branches = response.data.branches;
      })
      .catch(error => {
        console.log(error);
      });
    },
    addLocationFields(){
      this.department.location.push({
        inbound_location:"",
        inbound_location_ref:"",
        outbound_location:"",
        outbound_location_ref:"",
        is_default:0
      });
    },
    removeLocationField(i){
      this.department.location.splice(i,1);
    },
    setDefault(i){
      this.department.location.forEach(element => {
        element.is_default = 0;
      });
      this.department.location[i].is_default = 1;
    },
    submit(){
      console.log(this.department);
      this.$refs.observer.validate();
      this.axios.post("/api/department/",this.department)
        .then(response => {
            this.toast.success(response.data.msg);
            this.$emit('redirect');
        })
        .catch(error =>{
          console.log(error);
        });
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}
.jobs_table.sp table {
  width: 100%;
}
.jobs_table.sp table,
.jobs_table.sp table tr td {
  border: 0;
  padding: 0 0 20px 0;
  vertical-align: top;
}
.jobs_table.sp table tr td {
  width: 33%;
}
.colspan_flex_adj {
  display: flex;
  justify-content: space-around;
}
.colspan_flex_adj .label_and_element_wrapper {
  width: 100%;
}
.default_checkbox{    
  width:22px;   
  height:22px   
}
</style>